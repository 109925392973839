import React from 'react';
import styled from '@emotion/styled';

const Root = styled.footer`
  align-items: center;
  border-top: 1px solid #322241;
  background-color: #190023;
  color: white;
  display: flex;
  justify-content: center;
`;

const Container = styled.div`
  align-items: center;
  display: flex;
  flex-grow: 1;
  height: 48px;
  justify-content: space-between;
  max-width: 1280px;
  padding-left: 32px;
  padding-right: 32px;
`;

const LinksContainer = styled.div`
  a:not(:last-child) {
    margin-right: 16px;
  }
`;

const Link = styled.a`
  color: white;
  cursor: pointer;
  text-decoration: none;

  @media (hover: hover) and (pointer: fine) {
    &:hover {
      color: rgba(255, 255, 255, 0.8);
    }
  }
`;

const Footer = () => {
  return (
    <Root>
      <Container>
        <span>©2024</span>
        <LinksContainer>
          <Link
            aria-label="Jul Design Instagramm"
            href="https://www.linkedin.com/in/jul-shchukina/"
          >
            LinkedIn
          </Link>
          <Link aria-label="Jul Design Instagramm" href="https://www.instagram.com/jul.design.ux/">
            Instagram
          </Link>
          <Link aria-label="Jul Design Instagramm" href="https://www.behance.net/julshchukina">
            Behance
          </Link>
        </LinksContainer>
      </Container>
    </Root>
  );
};

export const MemoizedFooter = React.memo(Footer);
export default MemoizedFooter;
