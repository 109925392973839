exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-about-tsx": () => import("./../../../src/pages/about.tsx" /* webpackChunkName: "component---src-pages-about-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-projects-carrentalapp-tsx": () => import("./../../../src/pages/projects/carrentalapp.tsx" /* webpackChunkName: "component---src-pages-projects-carrentalapp-tsx" */),
  "component---src-pages-projects-driversguide-tsx": () => import("./../../../src/pages/projects/driversguide.tsx" /* webpackChunkName: "component---src-pages-projects-driversguide-tsx" */),
  "component---src-pages-projects-emergency-tsx": () => import("./../../../src/pages/projects/emergency.tsx" /* webpackChunkName: "component---src-pages-projects-emergency-tsx" */),
  "component---src-pages-projects-govtech-tsx": () => import("./../../../src/pages/projects/govtech.tsx" /* webpackChunkName: "component---src-pages-projects-govtech-tsx" */),
  "component---src-pages-projects-ido-tsx": () => import("./../../../src/pages/projects/ido.tsx" /* webpackChunkName: "component---src-pages-projects-ido-tsx" */),
  "component---src-pages-projects-shakemill-tsx": () => import("./../../../src/pages/projects/shakemill.tsx" /* webpackChunkName: "component---src-pages-projects-shakemill-tsx" */),
  "component---src-pages-projects-uiarchive-tsx": () => import("./../../../src/pages/projects/uiarchive.tsx" /* webpackChunkName: "component---src-pages-projects-uiarchive-tsx" */)
}

