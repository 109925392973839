import { Link } from 'gatsby';
import React from 'react';

const LinkList = () => {
  return (
    <>
      <li role="none">
        <Link to="/about">About</Link>
      </li>
    </>
  );
};

export default LinkList;
